<template>
    <div class="disposer-app-sequence">
        <div class="disposer-app-topic-title">
            Questions
        </div>
        <div class="questions-table">
            <div class="row-tr row-tr-head">
                <div class="number">
                    &#8470;
                </div>
                <div class="hide-section">
                    Hide Section
                </div>
                <div class="subtitle">
                    Subtitle
                </div>
                <div class="override_title">
                    Change subtitle
                </div>
                <div class="title">
                    Section title
                </div>

                <div class="intro-text">
                    Introductory text
                </div>

                <div class="question">
                    Question
                </div>

                <div
                    v-if="activePollingQuestions"
                    class="add-polling"
                >
                    Open polling question
                </div>

                <div class="private-comment">
                    Private Comment
                </div>


                <div class="hide-comment">
                    Hide Comment
                </div>

                <div class="edit-delete">
                    Save
                </div>
            </div>

            <question-row
                v-for="question in availableQuestions"
                :key="question.id"
                :question.sync="question"
                :topic-id="topicId"
                :event-id="eventId"
                :active-polling-questions="activePollingQuestions"
                @deleteQuestion="deleteQuestion"
                @getEditedTopic="getEditedTopic"
            />
        </div>
        <app-button @click="addQuestion">
            Add question
        </app-button>
    </div>
</template>

<script>
import appButton from '@/components/disposer/app/app-button';
import questionRow from '@/components/disposer/pages/topic/question-row';

export default {
    name: 'disposer-app-sequnce',

    components: {
        appButton,
        questionRow,
    },

    props: {
        eventId: {
            type: Number,
            default: null,
        },
        topicId: {
            type: Number,
            default: null,
        },
        availableQuestions: {
            type: Array,
            default: () => [],
        },
    },

    data: () => ({
        editedQuestion: {
            id: Math.random(),
            number: 1,
            subtitle: '',
            intro: '',
            name: '',

            surveys: [],
        },
    }),


    computed: {
        platform() {
            return this.$store.getters['platform/platform'];
        },
        activePollingQuestions() {
            return this.platform.settings.survey;
        },
    },

    methods: {
        addQuestion() {
            const availableQuestions = [...this.availableQuestions];
            availableQuestions.push({
                id: Math.random(),
                editDefault: true,
                surveys: [],
            });
            this.$emit('update:availableQuestions', availableQuestions);
        },
        deleteQuestion(id) {
            const questions = this.availableQuestions.filter(q => q.id !== id);
            this.$emit('update:availableQuestions', questions);
        },
        getEditedTopic() {
            this.$emit('getEditedTopic');
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-app-sequence {
    padding: 20px 0;

    .questions-table {
        position: relative;
        margin-bottom: 30px;
        background-color: white;

        .disposer-app-input {
            margin-top: 0;
        }
    }

    .questions-table {
        display: flex;
        flex-direction: column;
        width: 100%; /* Задаём ширину таблицы */
    }

    .row-tr, .row {
        display: flex;
        align-items: center;
        justify-content: flex-start; /* Можно использовать flex-start для лучшего контроля */
    }

    .row-tr-head {
        padding: 10px 0;
        border-bottom: 1px solid #999;
    }

    .number {
        flex: 0 0 5%; /* 5% ширины для номера */
        text-align: center;
        padding: 10px;
    }

    .subtitle {
        flex: 0 0 11%; /* 20% ширины для подзаголовка и заголовка секции */
        text-align: center;
        padding: 10px;
    }

    .override_title {
        flex: 0 0 8%; /* 15% ширины для изменения заголовка */
        text-align: center;
        padding: 10px;
    }

    .title {
        flex: 0 0 8%; /* 20% ширины для подзаголовка и заголовка секции */
        text-align: center;
        padding: 10px;
    }

    .intro-text {
        flex: 0 0 10%; /* 20% ширины для вводного текста */
        text-align: center;
        padding: 10px;
    }

    .question {
        flex: 0 0 20%; /* 20% ширины для вопроса */
        text-align: center;
        padding: 10px;
    }

    .add-polling {
        flex: 0 0 11%; /* 10% ширины для открывания опроса */
        text-align: center;
        padding: 10px;
        display: flex;
        justify-content: center;
    }

    .private-comment {
        flex: 0 0 7%; /* 5% ширины для приватного комментария и скрытого комментария */
        text-align: center;
        padding: 10px;
    }

    .hide-comment {
        flex: 0 0 7%; /* 5% ширины для приватного комментария и скрытого комментария */
        text-align: center;
        padding: 10px;
    }

    .hide-section {
        flex: 0 0 7%; /* 5% ширины для приватного комментария и скрытого комментария */
        text-align: center;
        padding: 10px;
    }

    .edit-delete {
        flex: 0 0 6%; /* 5% ширины для кнопок редактирования и удаления */
        text-align: center;
        padding: 10px;
        display: flex;
        justify-content: center;
    }


}

</style>
